import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Margin, } from '@components/atoms'
import { Hero } from '@components/molecules'
import { TransitionMask } from '@components/organisms'
import { PageProps } from '@types'
import { palette } from '@theme'
import { useMousePosition, useWindowSize, clamp } from '@utils'
import useInterval from 'react-useinterval'


const PADDLE_SPEED = 50
const PADDLE_WIDTH = 100
const BALL_SPEED = 5
const OBJECT_SIZE = 20


const NotFoundPage = ({data: { 
  sanityPage: {
    heroType, 
    heroHeight,
    heroFX,
    heroImageConditional, 
    heroVideoConditional,
    heroParallaxConditional,
    heroTextConditional,
    inverted,
  },
}}: PageProps) => {
  const windowSize = useWindowSize()
  const mousePos = useMousePosition()
  const [footerHeight, setFooterHeight] = React.useState(430)
  React.useEffect(() => setFooterHeight(typeof document !== 'undefined' ? document.getElementById('footer')?.clientHeight || 430 : 430), [windowSize])
    
  const [courtHeight, setCourtHeight] = React.useState(400)
  React.useEffect(() => {
    setCourtHeight(Math.floor(windowSize.height * .5))
  }, [windowSize])

  const [paddleX, setPaddleX] = React.useState(0 - (PADDLE_WIDTH / 2))
  const [ballPos, setBallPos] = React.useState({ x: 90, y: -1 * (courtHeight - 90) })
  const [ballDirection, setBallDirection] = React.useState({ x: 1, y: 1 })
  
  const animate = () => {
    const distance = mousePos.x - paddleX
    const this_ball_direction_y = ballPos.y < (courtHeight * -1 + OBJECT_SIZE * 2) && ballPos.y > (courtHeight * -1) && Math.abs(windowSize.width - ballPos.x - paddleX) < PADDLE_WIDTH / 2
        ? 1 // ball hit ceiling
        : ballPos.y > -1 * OBJECT_SIZE && ballPos.y < 0 && Math.abs(ballPos.x - paddleX) < PADDLE_WIDTH / 2
          ? -1 // bottom paddle intercepted ball
          : ballDirection.y // ball is falling into pit
    const this_ball_direction_x = ballPos.x <= 0
        ? 1
        : ballPos.x > windowSize.width
          ? -1
          : ballDirection.x
          
    if( ballPos.y > windowSize.height || ballPos.y < windowSize.height * -1 ) {
      // reset game
      setBallPos({ x: 90, y: -1 * (courtHeight - 90) })
      setBallDirection({ x: 1, y: 1 })
    } else {
      // keep it moving
      setBallDirection({ x: this_ball_direction_x, y: this_ball_direction_y })
      setBallPos({
        x: ballPos.x + BALL_SPEED * this_ball_direction_x,
        y: ballPos.y + BALL_SPEED * this_ball_direction_y
      })
    }
    setPaddleX(paddleX + clamp(distance, -1 * PADDLE_SPEED, PADDLE_SPEED))
  }
  
  // cute trick for keeping FPS under control: https://stackoverflow.com/a/39135659/82944
  const fps = 64
  useInterval(() => requestAnimationFrame(animate), 1000 / fps)

  return (
    <>
      <StyledMargin {...{ footerHeight }}>
        <Deadzone />
        <Deadzone right />
        <StyledHero {...{ heroType, heroHeight, heroImageConditional, heroVideoConditional, heroParallaxConditional, heroTextConditional, heroFX }} />
        <Game>
          <Ball {...{ ballPos }} />
          <Paddles>
            <Paddle {...{ paddleX }} />
            <Paddle paddleX={windowSize.width - paddleX} second />
          </Paddles>
        </Game>
      </StyledMargin>
            
      <TransitionMask {...{ inverted }} />
    </>
  )
}


const StyledHero = styled(props => <Hero {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 100px 0 50px 0;
  @media only screen and (min-width: 744px) {
    margin: 50px 0;
  }
`

const StyledMargin = styled(props => <Margin {...props} />)<{ footerHeight: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
`


const Paddles = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
`


const Game = styled.main`
  position: relative;
  height: 50vh;
  width: 100%;
`

const Deadzone = styled.div<{ right?: boolean }>`
  position: fixed;
in  z-index: 9999;
  top: 0;
  width: 24px;
  height: 100vh;
  ${props => props.right ? 'right: 0px;' : 'left: 0px'}
`


const Ball = styled.div.attrs(
  ({ ballPos }: { ballPos: { x: number, y: number } } ) => {
  return { style: {
    transform: `translate(${ballPos.x}px, ${ballPos.y}px)`
  } }}
)<{ ballPos: { x: number, y: number }}>`
  position: absolute;
  bottom: 0px;
  will-change: transform;
  transform-origin: center;
  width: ${OBJECT_SIZE}px;
  height: ${OBJECT_SIZE}px;
  border-radius: 9999px;
  background-color: ${palette.teak};
`


const Paddle = styled.div.attrs(
  ({ paddleX }: { paddleX: number } ) => {
  return { style: {
    transform: `translateX(${paddleX}px)`
  } }}
)<{ paddleX: number, second?: boolean }>`
  position: absolute;
  ${props => props.second ? 'top: 0px' : 'bottom: 0px'};
  will-change: transform;
  width: ${PADDLE_WIDTH}px;
  margin-left: ${PADDLE_WIDTH / -2}px;
  height: ${OBJECT_SIZE}px;
  background-color: ${palette.teak};
`



export const query = graphql`
  query NotFoundPageQuery {
    sanityPage(slug: {current: {eq: "not-found"}}) {
      ...pageFields
    }
  }
`


export default NotFoundPage
